import styled from "styled-components"
import Helmet from 'react-helmet';
import { useEffect, useState } from "react";
import { useRouter } from 'next/router'
const Head = () => {

    return (
        <Helmet>
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            <title>웹툰투자플랫폼 - M.Funds</title>
            <link rel="icon" href="/img/3d-cube.png" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://adultoon.mym-b.com/" />
            <meta property="og:title" content="M.Funds에 오신 것을 환영합니다!" />
            <meta property="og:description" content="콘텐츠를 소유하고 후원할 수  있는 형태인 다음 세대의 콘텐츠 가치 창출을 위한 공간" />
            <meta property="og:image" content="https://adultoon.mym-b.com/img/link_img.png" />
            <meta name="description" content="콘텐츠를 소유하고 후원할 수  있는 형태인 다음 세대의 콘텐츠 가치 창출을 위한 공간" />
            {/* <meta name="google-site-verification" content="+nxGUDJ4QpAZ5l9Bsjdi102tLVC21AIh5d1Nl23908vVuFHs34=" /> */}
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
            <link href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap" rel="stylesheet"></link>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <link
                href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css"
                rel="stylesheet"
                integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC"
                crossOrigin="anonymous" />
            <link rel='stylesheet'
                href='https://cdn.rawgit.com/konpa/devicon/df6431e323547add1b4cf45992913f15286456d3/devicon.min.css' />
            <link
                rel="stylesheet"
                href="https://cdn.jsdelivr.net/npm/swiper@8/swiper-bundle.min.css"
            />
            <script
                src="https://cdn.jsdelivr.net/npm/swiper@8/swiper-bundle.min.js"></script>
            <script src="https://kit.fontawesome.com/7a36eabb49.js"
                crossOrigin="anonymous"></script>
            <script
                defer
                src="https://developers.kakao.com/sdk/js/kakao.min.js"
            ></script>
            <script src="https://cdn.jsdelivr.net/npm/animejs@3.0.1/lib/anime.min.js"></script>
        </Helmet>
    );
}


export default Head