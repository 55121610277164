import styled from "styled-components"
import { useState, useRef, useEffect, useCallback } from 'react'
import $ from 'jquery'
import * as common from '../common/commonFunction'
import commonSession from '../common/commonSession'


const Footer = () => {

   // login session info 
   var sessionInfo = commonSession.getSession();
   var userId = sessionInfo.userId;
   var token = sessionInfo.token;
   var name = sessionInfo.nickName;
   var uid = sessionInfo.uid;


   const [moveLink, setMoveLink] = useState('');

  const MainFrame = styled.div`
    width:100%;
`

  const naviBox = useRef(null)

  useEffect(() => {


    if ((userId != '' && userId != null) && (token != '' && token != null)) {
       setMoveLink('');
    } else {
       setMoveLink('/login')
    }
})

  const changeChecked = (e) => {
    if (e.target.checked === true) {
      var id = e.target.id
      
      if(id == "menu-open"){
        if(moveLink != ''){
          //비로그인 시  로그인 페이지 이ㅇ
          alert('로그인 후 이용 가능하십니다.');
          location.href="/login";
        }
      }

      $('.container-fluid input[type="checkbox"]').not('#' + id).prop('checked', false)
    } else {
      $('.container-fluid input[type="checkbox"]').prop('checked', false)
    }
  }

  return (
    <MainFrame>
      <footer>
        <div className="footer-box d-flex justify-content-center w-90 text-center">
          <div className="footer-data p-3">
            (주) 밈비  ㅣ 대표 김주원
            사업자등록번호 ㅣ 765-81-02588 <br />
            통신판매 번호 ㅣ 2022-서울강남-05368 <br />
            대표전화 ㅣ 02-541-0917 <br />
            호스팅 제공자 ㅣ (주) 밈비 <br />
            주소 ㅣ 서울시 강남구 논현로 149길 54, 401호<br />
            <a onClick={() => window.open('http://www.ftc.go.kr/bizCommPop.do?wrkr_no=7658102588', '사업자 정보 확인', 'width=600, height=700, location=no,status=no, scrollbars=yes')} style={{ cursor: "pointer" }}>사업자 정보 확인</a>
          </div>
          <div className="footer-data ml-2 p-3">
            MYMB Co., Inc. | Representative: Kim Joo won  <br />
            Business registration number: 765-81-02588  <br />
            {/* Confirmation of business information  <br /> */}
            {/* Mail order number:               ㅣ  <br /> */}
            Hosting provider: MYMB Co., Inc. ,  <br />
            Address:  401Ho , 54, Nonhyeon-ro 149-gil, Gangnam-gu, Seoul
            <div className="snsBox">
              <i className="fa-solid fa-comment" onClick={()=> window.open('https://open.kakao.com/o/goDUcVHe')}></i>
              <i className="fa-brands fa-twitter" onClick={()=> window.open('https://twitter.com/mymb00')}></i>
              <i className="fa-brands fa-discord" onClick={()=> window.open('https://discord.com/channels/1032524286918930453/1032524286918930455')}></i>
              <i className="fa-brands fa-telegram" onClick={()=> window.open('https://t.me/mymb220211')}></i>
              <i className="fa-brands fa-instagram" onClick={()=> window.open('https://www.instagram.com/mymb00/')}></i>
              <i className="fa-brands fa-youtube" onClick={()=> window.open('https://www.youtube.com/channel/UCRaVIX7bqTA8svlVkJeD9PA')}></i>
              <i className="fa-brands fa-facebook" onClick={()=> window.open('https://www.facebook.com/people/%EB%B0%88%EB%B9%84/100083120873826/')}></i>
            </div>
          </div>
          <div className="footer-terms-list text-start p-3">
            <div className="terms-list">
              <p><a onClick={() => location.href = '/terms/termsOfUse'} style={{ cursor: "pointer" }}>이용약관</a></p>
              <p><a onClick={() => location.href = '/terms/termsOfPrivacy'} style={{ cursor: "pointer" }}>개인정보처리방침</a></p>
              <p>고객센터: support@mym-b.com</p>
            </div>
          </div>
        </div>
        {/* <div className="bottom-nav-bar">
          <nav className="navbar navbar-dark fixed-bottom" aria-label="Dark
            offcanvas navbar" style={{ bottom: "4%" }}>
            <div className="container-fluid">
              <nav className="project">
                <input type="checkbox" href="#" className="project-open"
                  name="project-open" id="project-open" onClick={changeChecked} />
                <label className="project-open-button open-items" htmlFor="project-open">
                    <i class="fa-solid fa-video" style={{ color: "#2C3D4F", fontSize: "22pt", lineHeight: 2.4 }}></i>
                </label>
                <a href="/project/Main" className="project-item">project</a>
              </nav> 
              <nav className="menu">
                <input type="checkbox" href="#" className="menu-open"
                  name="menu-open" id="menu-open" onClick={changeChecked} />
                <label className="menu-open-button  open-items" htmlFor="menu-open">
                  <img className="bottomMenu" src="/img/cube.png" />
                </label>
                <a href="/pom" className="menu-item poM">PoM</a>
                <a href="/mymbId" className="menu-item mymbID">MYM.B-ID</a>
                <a href="/wallet" className="menu-item wallet">Inventory</a>

                <input type="checkbox" href="#" className="menu-open"
                  name="menu-open" id="menu-open"
                  onClick="location.href='/login'" />
                  <label className="menu-open-button" htmlFor="menu-open">
                  <img className="bottomMenu" src="/img/logo-symbol.png" />
                  <i class="fa-solid fa-house" style={{ color: "#2C3D4F", fontSize: "22pt", lineHeight: 2.4 }}></i>
                  </label>
              </nav>
              <nav className="comm">
                <input type="checkbox" href="#" className="comm-open"
                  name="comm-open" id="comm-open" onClick={changeChecked} />
                <label className="comm-open-button  open-items" htmlFor="comm-open">
                   <i class="fa-solid fa-people-group" style={{ color: "#2C3D4F", fontSize: "22pt", lineHeight: 2.4  }}></i>
                </label>
                <a href="/freeCommunity" className="comm-item frComm">Community</a>
                <a href="/HallOfFame" className="comm-item Hot">H.o.F</a>
              </nav>
            </div>
          </nav>
        </div> */}
      </footer>
      <script src="https://code.jquery.com/jquery-3.5.1.min.js"></script>
      <script src="/js/common.js"></script>
    </MainFrame>
  );
}


export default Footer
