import axios from 'axios';
import commonSession from "../common/commonSession"
import $ from "jquery"


var sessionInfo = commonSession.getSession()

var token = sessionInfo.token
var userId = sessionInfo.userId
var uid = sessionInfo.uid
var nickName = sessionInfo.nickName

console.log(sessionInfo)

// logout 
export function logout() {

    commonSession.setSession('', '', '', '')
    window.location.href = '/login'

}


export function getDDay(expiry_date) {
    // D-Day 날짜 지정
    const setDate = new Date(expiry_date); //열람만료일 기준
    //YMDFormatter 함수를 쓴 이유는 expiry_date가 "20220526" 형태로 넘어오기 때문에
    //한번 더 포맷 해줌

    // 현재 날짜를 new 연산자를 사용해서 Date 객체를 생성
    const now = new Date();

    // D-Day 날짜에서 현재 날짜의 차이를 getTime 메서드를 사용해서 밀리초의 값으로 가져온다.
    const distance = setDate.getTime() - now.getTime();
    // Math.floor 함수를 이용해서 근접한 정수값을 가져온다.
    // 밀리초 값이기 때문에 1000을 곱한다.
    // 1000*60 => 60초(1분)*60 => 60분(1시간)*24 = 24시간(하루)
    // 나머지 연산자(%)를 이용해서 시/분/초를 구한다.
    const day = Math.floor(distance / (1000 * 60 * 60 * 24));

    return day + 1;
}

// buyTicket
export function buyTicket() {


    var auth = 'Bearer ' + token;

    const option = {
        url: '/payment/paypal/submit',
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
            "Authorization": auth
        }
    }

    axios(option)
        .then(response => {
            console.log(response);
            var redirectUrl = response.data.data.redirect;
            // console.log(redirectUrl);
            window.open(redirectUrl, "_blank")
        })

}

export function pageChange() {
    window.opener.location.href = "/createMymC";
    window.close();
}


// login
export function loginFunc(enterprise) {

    var userEmail = document.getElementById("userEmail").value;
    var pw = document.getElementById("pw").value;

    if (userEmail == "") {
        $('.toast .toast-body').html('Please insert email.');
        showToast('')
        return false;
    } else if (pw == "") {
        $('.toast .toast-body').html('Please insert password.');
        showToast('')
        return false;
    }

    var data = { email: userEmail, password: pw,enterprise:enterprise };
    var jsonData = JSON.stringify(data);


    const option = {
        url: '/users/login',
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
        },
        data: jsonData
    }

    axios(option)
        .then(response => {

            console.log(response);

            if(response.code == 401 || response.data.code == 401){
                $('.toast .toast-body').html("미승인된 사용자입니다. 관리자에게 문의하세요.");
                showToast('')
                return false;
            }

            var headers = response.headers;

            var userData = { 'userId': headers.userid, 'firstName': headers.firstName ,  'lastName': headers.lastName , 'token': headers.token, 'nickName': headers.nickname, 'uid': headers.useruid };

            if (userEmail == userData.userId && userData.token != '') {

                var auth = 'Bearer ' + userData.token;

                var option = {
                    url: "/users/info",
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json;charset=UTF-8',
                        "Authorization": auth
                    }
                }
        
                axios(option)
                    .then(response => {
                        commonSession.setSession(userData.userId, userData.token, userData.nickName, userData.uid, response.data.data.firstName, response.data.data.lastName, response.data.data.inviterEmail, response.data.data.enterprise, response.data.data.certificated)
                        
                        // URL 파라미터를 파싱하기 위해 URLSearchParams 사용
                        const urlParams = new URLSearchParams(window.location.search);
                        const returnUrl = urlParams.get('returnUrl');

                        // returnUrl이 있고 유효한 경우에만 해당 URL로 리다이렉트
                        if (returnUrl && returnUrl.trim() !== '') {
                            window.location.href = decodeURIComponent(returnUrl);
                        } else {
                            window.location.href = '/';
                        }
                    });
                
               
            } else {
                $('.toast .toast-body').html("로그인 실패. 다시 시도해 주십시오.");
                showToast('')
            }
        })
        .catch(function (error) {
            if (error.response) {
                if (error.response.status == '401') {
                    $('.toast .toast-body').html("로그인 실패. 다시 시도해 주십시오.");
                    showToast('')
                }
            } else {
                $('.toast .toast-body').html('서버 연결 도중 에러가 났습니다. 다시 시도해 주십시오.');
                showToast('')
            }
        })
}

// move to link

export function moveToLink(link) {
    location.href = link;
}

// write on community
export function post() {

    let hashTagVal = document.getElementById("hashtag").value;
    let hashArr = hashTagVal.split("#");
    let hashTag = "";
    let count = 0;

    for (var i = 0; i < hashArr.length; i++) {
        if (hashArr[i] != "" && count < 5) {
            if (i != 0 && hashTag != "") {
                hashTag += ","
            }
            hashTag += hashArr[i];
            count++;
        }
    }

    var auth = 'Bearer ' + token;

    var data = {
        "title": document.getElementById("title").value,
        "summary": document.getElementById("summary").value,
        "content": document.getElementById("content").value,
        "author": userId,
        "hashtag": hashTag,
        "fileId": document.getElementById("fileId").value,
        "fileUrl": document.getElementById("fileUrl").value,
    };

    var jsonData = JSON.stringify(data);

    const option = {
        url: "/community/free/api/post",
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
            "Authorization": auth
        },
        data: jsonData
    }

    console.log(auth);


    axios(option)
        .then(response => {
            console.log(response);
            if (response.data.message == "Success" || response.data.message == "Success.") {
                $('.toast .toast-body').html('작성완료');
                showToast('/freeCommunity')
            }
        })
}


export function deletePost(id) {

    var auth = 'Bearer ' + token;

    const option = {
        url: "/community/free/api/post",
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
            "Authorization": auth
        },
        data: {
            id: id
        }
    }

    console.log(auth);


    axios(option)
        .then(response => {
            console.log(response);
            if (response.data.message == "Success" || response.data.message == "Success.") {
                $('.toast .toast-body').html('삭제완료');
                showToast('/freeCommunity')
            }
        })
}


export function reply(id) {
    if (token != "" && userId != "") {

        var textarea = $('.replyBtn[data-id="' + id + '"]').parent("div.card-body").find("form textarea.replyContent");
        var replyContent = $(textarea).val();
        var boardId = $('.replyBtn[data-id="' + id + '"]').closest("li.board").attr("id")

        if (replyContent == "") {
            $('.toast .toast-body').html('댓글 내용을 입력해주세요');
            showToast('')
            return false;
        }
        var data = { content: replyContent, parentComment: id };
        var jsonData = JSON.stringify(data);
        var auth = "Bearer " + token;

        $.ajax({
            type: "POST",
            dataType: "json",
            url: "/community/api/reply/" + id,
            data: jsonData,
            contentType: "application/json; charset=utf-8",
            beforeSend: function (xhr) {
                xhr.setRequestHeader("Authorization", auth);
            },
            success: function (result) {
                // console.log(result);

                // if (result.status == 200 && result.message == "Added reply") {

                //     var html = "<div className='d-flex mb-4' id=\"" + result.data.id + "\"><div className='flex-shrink-0'><img className='rounded-circle' src='https://dummyimage.com/50x50/ced4da/6c757d.jpg' alt='...'></div>";
                //     html += "<div className='ms-3' style={{textOverflow: 'ellipsis', width: '85%'}}><div className='fw-bold' style={{minHeight:'25px'}}>" + result.data.authorUser.nickName;
                //     html += "<button type=\"button\" className=\"reFormBtn\" style=\"float: right; border-radius: 1em; padding:0px 4px;\">reply</button></div>";
                //     html += result.data.content + "</div></div>";
                //     html += "<form className=\"mb-4 replyTarget\" style={{display:'none'}} htmlFor=\"" + result.data.id + "\">";
                //     html += "<textarea className=\"form-control reCommentContent\" rows=\"3\"></textarea></form>";
                //     html += "<button type=\"button\" className=\"mb-4 reComment w-100 replyTarget\" onclick=\"reComment(this,'" + result.data.id + "','" + boardId + "')\" htmlFor=\"" + result.data.id + "\" style={{display:'none',cursor: 'pointer'}}>reply</button>";

                // }

                // $('.replyBtn[data-id="' + id + '"]').parents("div.card-body").append(html);
                // $(textarea).val("");
                alert("댓글 작성 완료");
                $('.toast .toast-body').html("댓글 작성 완료");
                showToast('reload')
            },
            error: function (request, status, error) {
                $('.toast .toast-body').html('서버 연결 도중 에러가 났습니다. 다시 시도해 주십시오.');
                showToast('')
            }
        });

        alert("댓글 작성 완료");
        $('.toast .toast-body').html("댓글 작성 완료");
        showToast('reload')

    } else {
        $('.toast .toast-body').html('댓글은 로그인 후 이용가능합니다.');
        showToast('')
        return false;
    }
}


//rereply on community
export function reComment(id, bid) {
    if (userId != "" && token != "") {

        var textarea = $('.recommentBtn[data-id="' + id + '"]').siblings("form.replyTarget[for='" + id + "']").find("textarea");
        var reCommentContent = $('.recommentBtn[data-id="' + id + '"]').siblings("form.replyTarget[for='" + id + "']").find("textarea").val()

        if (reCommentContent == "") {
            $('.toast .toast-body').html('댓글 내용을 입력해주세요');
            showToast('')
            return false;
        }

        var data = { content: reCommentContent, parentComment: id };
        var jsonData = JSON.stringify(data);
        var auth = "Bearer " + token;

        $.ajax({
            type: "POST",
            dataType: "json",
            url: "/community/api/reply/" + bid,
            data: jsonData,
            contentType: "application/json; charset=utf-8",
            beforeSend: function (xhr) {
                xhr.setRequestHeader("Authorization", auth);
            },
            success: function (result) {

                if (result.status == 200 && result.message == "Added reply") {
                    $('.recommentBtn[data-id="' + id + '"]').hide();
                    $('.recommentBtn[data-id="' + id + '"]').siblings("form.replyTarget[for='" + id + "']").val("");
                    $('.recommentBtn[data-id="' + id + '"]').siblings("form.replyTarget[for='" + id + "']").hide();

                    var parentArea = $('.recommentBtn[data-id="' + id + '"]').siblings(".d-flex.mb-4[id='" + id + "']");

                    var html = "<div className=\"d-flex mt-4\" id=\"" + result.data.id + "\">";
                    html += "<div className=\"flex-shrink-0\"><img className=\"rounded-circle\" src=\"https://dummyimage.com/50x50/ced4da/6c757d.jpg\" alt=\"...\"></div>";
                    html += "<div className=\"ms-3\"><div className=\"fw-bold\">" + result.data.authorUser.nickName + "</div>";
                    html += result.data.content + "</div></div>";

                    $(parentArea).children("div.ms-3").append(html);

                    alert("댓글 작성 완료");
                    $('.toast .toast-body').html("댓글 작성 완료");
                    showToast('')
                }

            },
            error: function (request, status, error) {
                $('.toast .toast-body').html('서버 연결 도중 에러가 났습니다. 다시 시도해 주십시오.');
                showToast('')
            }
        });

        alert("댓글 작성 완료");
        $('.toast .toast-body').html("댓글 작성 완료");
        showToast('')
        
    } else {
        $('.toast .toast-body').html('댓글은 로그인 후 이용가능합니다.');
        showToast('')
        return false;
    }
}


//임시 검색 함수 
export function search() {
    let frm = document.search_form;
    let keyword = document.querySelector(".search-input").value;
    frm.action = "/freeCommunity";

    if (keyword != "") {
        frm.keyword.value = keyword;
        frm.isSearch.value = "Y";
    } else {
        frm.isSearch.value = "N";
    }
    frm.submit();
}


//like in community 
export function like(id, checked) {

    console.log(id);

    // var target = $('.fa-regular.fa-heart[data-id="' + id + '"]');
    var isChecked = $('.fa-regular.fa-heart[data-id="' + id + '"]').hasClass("checked");

    var board = $('.fa-regular.fa-heart[data-id="' + id + '"]').closest("li.board");
    var boardId = $(board).attr("id");

    console.log(userId);
    console.log(token);
    console.log(isChecked);

    if (userId != null && token != null && userId != "" && token != "") {
        var auth = "Bearer " + token;

        if (isChecked) {
            $('.toast .toast-body').html('이미 좋아요 한 글입니다.');
            showToast('')
            return false;
        }

        $.ajax({
            type: "POST",
            dataType: "json",
            url: "/community/free/api/post/like/" + boardId,
            contentType: "application/json; charset=utf-8",
            beforeSend: function (xhr) {
                xhr.setRequestHeader("Authorization", auth);
            },
            success: function (result) {
                $('.fa-regular.fa-heart[data-id="' + id + '"]').addClass("checked");
                var beforeLikeCnt = Number(($('.hand-spock-count[data-id="' + id + '"]').text().trim()));
                console.log(beforeLikeCnt);
                $('.hand-spock-count[data-id="' + id + '"]').text(Number(beforeLikeCnt) + 1);

            },
            error: function (request, status, error) {
                $('.toast .toast-body').html('서버 연결 도중 에러가 났습니다. 다시 시도해 주십시오.');
                showToast('')
            }
        });

    } else {
        $('.toast .toast-body').html('로그인 후 이용가능합니다.');
        showToast("/login")
        return false;
    }

}

// email verificate for join  
export function email_send() {

    var userMail = document.querySelector("#userMail").value;

    if (userMail == "") {
        $(".invalid-feedback.userMail").show();
        $("div.email-verificate").hide();
    } else {
        $("div.email-verificate").show();	//íì¤í¸ì©ì¼ë¡ ìì 

        var regEmail = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;
        if (regEmail.test(userMail) === true) {

            $("div.email-verificate").show();	//íì¤í¸ì©ì¼ë¡ ìì 
            $(".invalid-feedback.userMail").hide();

            var data = { "email": userMail.trim() };
            var jsonData = JSON.stringify(data);

            $.ajax({
                type: "POST",
                dataType: "json",
                url: "/users/email",
                data: jsonData,
                contentType: "application/json; charset=utf-8",
                success: function (result) {
                    console.log(result);
                    if (result.data == "true" || result.data == true) {
                        $("div.email-verificate").show();
                        $("div.email-verificate").find("input:text").val(data.key);
                        alert('인증번호를 메일로 전송하였습니다.')
                    }else if(result.message.indexOf('Duplicate data') != -1){
                        alert('이미 가입된 이메일입니다. 다른 이메일로 시도해 주세요.')
                    }
                },
                error: function (e) {
                    $('.toast .toast-body').html('서버 연결 도중 에러가 났습니다. 다시 시도해 주십시오.');
                    showToast('')
                }
            });
        } else {
            $(".invalid-feedback.userMail").show();
            $("div.email-verificate").hide();
        }
    }
}

//check duplication of nickname
export function chk_nickname() {
    let nickName = document.querySelector("#nickname").value;

    if (nickName == "") {
        $(".invalid-feedback.nickname").show();
    } else {
        var data = { 'nickName': nickName };
        var jsonData = JSON.stringify(data);

        $.ajax({
            type: "GET",
            url: "/users/checkNickName?nickname="+nickName,
            contentType: "application/json; charset=utf-8",
            success: function (result) {
                // console.log(result);
                if (result.data == true || result.data == "true") {
                    console.log($('input[name="nickFlag"]'))
                    $('input[name="nickFlag"]').val('done')
                    $('.toast .toast-body').html(result.message);
                    showToast('')
                    $(".invalid-feedback.nickname").hide();
                    $(".nickCheckBtn").hide();
                } else {
                    $('.toast .toast-body').html(result.message);
                    showToast('')
                    $(".invalid-feedback.nickname").show();
                }
            },
            error: function (e) {
                $('.toast .toast-body').html('서버 연결 도중 에러가 났습니다. 다시 시도해 주십시오.');
                showToast('')
            }
        });
    }
}

//check correct verifyCode
export function verifyCode() {
    var key = $("div.email-verificate").find("input:text").val();
    var userMail = document.querySelector("#userMail").value;

    if (key == "") { $('.toast .toast-body').html("인증코드를 입력해주세요"); showToast('') }
    else {
        var data = { email: userMail, code: key };
        var jsonData = JSON.stringify(data);
        $.ajax({
            type: "POST",
            dataType: "json",
            url: "/users/verifyCode",
            data: jsonData,
            contentType: "application/json; charset=utf-8",
            success: function (result) {
                if (result.data == "true" || result.data == true) {
                    $("div.email-verificate").hide();
                    $("div.invalid-feedback.userMail").hide();
                    $('.toast .toast-body').html("인증되었습니다.");
                    showToast('')
                    $(".sendEmail").hide();
                } else {
                    $('.toast .toast-body').html("인증을 실패했습니다.");
                    showToast('')
                }
            },
            error: function (e) {
                $('.toast .toast-body').html('서버 연결 도중 에러가 났습니다. 다시 시도해 주십시오.');
                showToast('')
            }
        });
    }
}



///get userinfo 

export function getUserInfo() {
    if (userId != "" && token != "" && userId != null && token != null) {
        var auth = "Bearer " + token;

        const option = {
            url: "/card/mymb/v1/card/user/" + uid,
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                "Authorization": auth
            }
        }

        axios(option)
            .then(result => {
                // if (result.status == 200) {
                console.log(result);
                // window.self.close();
                // }
            })
    } else {

    }
}


//get cardinfo 

export async function getCardInfo(cardId) {
    //카드정보는 hof 때문에 비회원일때도 보여야하므로 권한 삭제요청함

    const option = {
        url: "/card/mymb/v1/card/" + cardId,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
        }
    }

    await axios(option)
        .then(result => {
            console.log(result);
            return result;
        })
}


//get upload files 
export function getUploadFiles() {
    if (userId != "" && token != "" && userId != null && token != null) {

        var auth = "Bearer " + token;

        $.ajax({
            type: "GET",
            dataType: "json",
            url: "/upload/getFiles",
            contentType: "application/json; charset=utf-8",
            beforeSend: function (xhr) {
                xhr.setRequestHeader("Authorization", auth);
            },
            success: function (result) {
                // 				 			    	console.log(result);			    	
                let targetArea = document.getElementById("cube");

                var mainFileIdx = 0;
                $(result.data).each(function (idx) {
                    if ($(this)[0].id == mainFileId) {
                        mainFileIdx = idx;
                    }
                });
                // console.log(result.data[mainFileIdx]);
                let src = result.data[mainFileIdx].fullpath;
                let type = result.data[mainFileIdx].contentType;
                let id = result.data[mainFileIdx].id;
                let html = "";
                if (type.includes("image")) {
                    html = "<img src=\"/filePath/" + src + "\" style=\"width:100%; height:100%;\"/>"
                } else if (type.includes("video")) {
                    html = "<video autoplay muted style=\"width:100%; height:100%;\"><source src=\"/filePath/" + src + "\" type=\"" + type + "\"></video>"
                } else {
                    html = "<img src=\"/filePath/" + src + "\" style=\"width:100%; height:100%;\"/>"
                }
                //				 		    		html = "<video autoplay muted id=\"media\" style=\"width:100%; height:100%;\"><source src=\"https://adultoon.mym-b.com/filePath/62f3b670f5378534a3236e15/2fa8a144-3e8b-4550-9ebd-ef42c9bdfde3__toad.mp4\" type=\"video/mp4\"></video>";		    		
                $(targetArea).find(".side_front .mymC_wrap #mymC_meme").html(html);

                // 						    		console.log(result.data);

                $(result.data).each(function (idx) {
                    if ($(this).id != mainFileId) {
                        // 											console.log($(this)[0].fullpath);
                        /* if($(this)[0].contentType.includes("image")){}else */
                        if ($(this)[0].contentType.includes("video")) {
                            var tgt = "<div className=\"collection\"><div className=\"mymC_wrap\">";
                            tgt += "<video autoplay muted style=\"width:100%; height:100%;\"><source src=\"/filePath/" + $(this)[0].fullpath + "\" type=\"" + $(this)[0].contentType + "\"></video>"
                            tgt += "<div className=\"mymC_memeName row\"><div style=\"float: left; font-size: .5rem; margin-top: .7rem; width: 4rem;\">#NAME</div>";
                            tgt += "<div style=\"text-align: right; margin-top: .7rem;\"><img style=\"width: 1.5rem; padding: 0rem; background: none; border-radius: 0px;\" src=\"img/logo-m.png\">";
                            tgt += "</div></div></div></div>";
                            $(".collectionWrap").append(tgt);
                        } else {
                            var tgt = "<div className=\"collection\"><div className=\"mymC_wrap\">";
                            tgt += "<img className=\"tgt\" src=\"/filePath/" + $(this)[0].fullpath + "\">";
                            tgt += "<div className=\"mymC_memeName row\"><div style=\"float: left; font-size: .5rem; margin-top: .7rem; width: 4rem;\">#NAME</div>";
                            tgt += "<div style=\"text-align: right; margin-top: .7rem;\"><img style=\"width: 1.5rem; padding: 0rem; background: none; border-radius: 0px;\" src=\"img/logo-m.png\">";
                            tgt += "</div></div></div></div>";
                            $(".collectionWrap").append(tgt);
                        }
                    }



                });



            },
            error: function (request, textStatus, errorThrown) {
                console.log(errorThrown);
            }
        });

    }
}

//getUserInfo in buyTicketPopup
export function getUserInfoTicket() {

    if (userId != "" && token != "" && userId != null && token != null) {
        var auth = "Bearer " + token;

        $.ajax({
            type: "GET",
            dataType: "json",
            url: "/users/info",
            contentType: "application/json; charset=utf-8",
            beforeSend: function (xhr) {
                xhr.setRequestHeader("Authorization", auth);
            },
            success: function (result) {
                console.log(result)
                $("#currentTicket").val(result.data.ticketCount);
            },
            error: function (request, textStatus, errorThrown) {
                $('.toast .toast-body').html('서버 연결 도중 에러가 났습니다. 다시 시도해 주십시오.');
                showToast('')
            }
        });
    } else {
        if (typeof document !== 'undefined') {
            // $('.toast .toast-body').html('로그인 후 이용가능합니다.');
            // location.href = "/login"
        }
        // pageChange(); 
    }
}


//getOtherUserinfo
export function getUserInfoOther(id) {

    $.ajax({
        type: "GET",
        dataType: "json",
        url: "/users/info",
        contentType: "application/json; charset=utf-8",
        data: {
            userId: id
        },
        success: function (result) {
            // console.log(result)
        },
        error: function (request, textStatus, errorThrown) {
            $('.toast .toast-body').html('서버 연결 도중 에러가 났습니다. 다시 시도해 주십시오.');
            showToast('')
        }
    });
}


//get userInfo in createMymC

export function getUserInfoMymC() {
    if (userId != "" && token != "" && userId != null && token != null) {
        var auth = "Bearer " + token;

        var mainCardId = "";

        $.ajax({
            type: "GET",
            dataType: "json",
            url: "/users/info",
            contentType: "application/json; charset=utf-8",
            beforeSend: function (xhr) {
                xhr.setRequestHeader("Authorization", auth);
            },
            success: function (result) {
                console.log(result);
                $("#currentTicket").val(result.data.ticketCount);
                mainCardId = result.data.mainCardId;
            },
            error: function (request, textStatus, errorThrown) {
                $('.toast .toast-body').html('서버 연결 도중 에러가 났습니다. 다시 시도해 주십시오.');
                showToast('')
            }
        });


    } else {

        if (typeof document !== 'undefined') {
            $('.toast .toast-body').html('로그인 후 이용가능합니다.');
            showToast("/login")
            // location.href = "/login"
            return false;
        }
    }
}



// upload file function 

export function upload() {

    var data = {
        "fileTimestampSha": document.getElementById("fileTimestampSha").value,
        "fileName": document.getElementById("fileName").value,
        "fileType": document.getElementById("fileType").value,
        "ownerUIDSha": document.getElementById("ownerUIDSha").value,
        "fileFullPath": document.getElementById("fileFullPath").value,
        // "description": opener.document.getElementById("description").value ?? ' ',
        // "mymName": opener.document.getElementById("mymName").value ?? ' ',
        "description": ' ',
        "mymName": ' ',
    };

    var jsonData = JSON.stringify(data);



    if (userId != "" && token != "" && userId != null && token != null) {

        var auth = "Bearer " + token;

        const option = {
            url: "/upload/fileData",
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                "Authorization": auth
            },
            data: jsonData,
        }

        axios(option)
            .then(result => {
                if (result.status == 200) {
                    if (result.data.data.id != '' && result.data.data.fileFullPath != '') {
                        $('.toast .toast-body').html(result.data.message);
                        showToast('')
                        opener.document.getElementById('fileId').value = result.data.data.id
                        opener.document.getElementById('fileUrl').value = result.data.data.fileFullPath
                        opener.$('body').find('#fileUrl').trigger('change');
                        setTimeout(() => {
                            window.self.close();
                        }, 3000);
                    } else {
                        alert('파일이 정상 업로드 되지 않았습니다. 재시도해주세요.')
                        return false;
                    }
                }
            })

    } else {
        if (typeof document !== 'undefined') {
            $('.toast .toast-body').html('로그인 후 이용가능합니다.');
            showToast("/login")
            // location.href = "/login"
        }
    }

}


//findPasswd function 
export function findPassword() {

    const option = {
        url: "/users/changePassword/"+$('#userEmail').val() ?? '',
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
        },
    }

    axios(option)
        .then(response => {
            if (response.status == 200) {
                $('.toast .toast-body').html('해당 메일로 초기화 링크를 보냈습니다.');
                showToast('')
            }
        })
        .catch(function (error) {
            // if (status == "501" || status == "502") {
            //     console.log(request.responseText);
            //     return false;
            // } else {
            $('.toast .toast-body').html('서버 연결 도중 에러가 났습니다. 다시 시도해 주십시오.');
            showToast('')
            return false;
            // }
        })
}


//send temp password function 
export function sendTempPassword(email,token) {

    const option = {
        url: "/users/changePasswordRequest?email="+email+'&token='+token,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
        },
    }

    axios(option)
        .then(response => {

            console.log(response);
            if (response.status == 200) {
                // setTimeout(() => {
                //     window.self.close();
                // }, 3000);
            }
        })
        .catch(function (error) {
            // if (status == "501" || status == "502") {
            //     console.log(request.responseText);
            //     return false;
            // } else {
            $('.toast .toast-body').html('서버 연결 도중 에러가 났습니다. 다시 시도해 주십시오.');
            showToast('')
            return false;
            // }
        })
}


// update view cnt
export function updateViewCnt(id) {

    const option = {
        url: "/community/free/api/viewCount/" + id,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
        }
    }

    axios(option)
        .then(response => {
            if (response.status == 200) {
                // $('.toast .toast-body').html(response.message);
                // location.reload()
            }
        })
        .catch(function (error) {
            // if (status == "501" || status == "502") {
            //     console.log(request.responseText);
            //     return false;
            // } else {
            $('.toast .toast-body').html('서버 연결 도중 에러가 났습니다. 다시 시도해 주십시오.');
            showToast('')
            return false;
            // }
        })

}

//send trust info 
export function sendTrust(id) {
    if (userId != "" && token != "" && userId != null && token != null) {

        var auth = "Bearer " + token;

        const option = {
            url: "/users/trust/" + id,
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                "Authorization": auth
            }
        }

        axios(option)
            .then(response => {
                if (response.status == 200) {
                    $('.toast .toast-body').html(response.data.message);
                    showToast('reload')
                    // location.reload()
                }
            })
            .catch(function (error) {
                // if (status == "501" || status == "502") {
                //     console.log(request.responseText);
                //     return false;
                // } else {
                $('.toast .toast-body').html('서버 연결 도중 에러가 났습니다. 다시 시도해 주십시오.');
                showToast('')
                return false;
                // }
            })

    } else {
        if (typeof document !== 'undefined') {
            $('.toast .toast-body').html('로그인 후 이용가능합니다.');
            showToast("/login")
            // location.href = "/login"
        }
    }
}

// useTicket 

export function useTicket(ticket) {
    if (userId != "" && token != "" && userId != null && token != null) {

        var auth = "Bearer " + token;
        const option = {
            url: '/users/useTicket',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                "Authorization": auth
            },
            data: {
                currentTicket: ticket
            }
        }

        axios(option)
            .then(response => {
                console.log(response);
            })
            .catch(function (error) {
                // if (status == "501" || status == "502") {
                //     console.log(request.responseText);
                //     return false;
                // } else {
                $('.toast .toast-body').html('서버 연결 도중 에러가 났습니다. 다시 시도해 주십시오.');
                showToast('')
                // return false;
                // }
            })

    } else {
        if (typeof document !== 'undefined') {
            $('.toast .toast-body').html('로그인 후 이용가능합니다.');
            showToast("/login")
            // location.href = "/login"
        }
    }
}

//get halloffame 
export function getHallOfFame() {

    const option = {
        url: '/users/hof',
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
        }
    }

    axios(option)
        .then(response => {
            console.log(response);
        })
        .catch(function (error) {
            // if (status == "501" || status == "502") {
            //     console.log(request.responseText);
            //     return false;
            // } else {
            $('.toast .toast-body').html('서버 연결 도중 에러가 났습니다. 다시 시도해 주십시오.');
            showToast('')
            // return false;
            // }
        })

}

export function publishMymId() {

    var cardCnt = $("#currentTicket").val()

    if (cardCnt > 0) {
        if (userId != "" && token != "" && userId != null && token != null) {

            var auth = "Bearer " + token;

            var data = {
                fileURI: document.getElementById('fileUrl').value,
                ownerUid: uid,
                hashtag: document.getElementById('hashtag').value,
                cardCategory: document.getElementById('cardCategory').value ?? 'Normal',
                fileId: document.getElementById('fileId').value,
                description: document.getElementById('description').value,
                mymName: document.getElementById('mymName').value,
                ownerMymid : userId
            };

            var jsonData = JSON.stringify(data);


            const option = {
                url: '/card/mymb/v1/card',
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                    "Authorization": auth
                },
                data: jsonData
            }

            axios(option)
                .then(response => {
                    console.log(response);
                    $('.toast .toast-body').html('Published successfully');
                    useTicket($("#currentTicket").val())
                    showToast('/wallet')
                    // window.location = '/wallet';
                })
                .catch(function (error) {
                    // if (status == "501" || status == "502") {
                    //     console.log(request.responseText);
                    //     return false;
                    // } else {
                    $('.toast .toast-body').html('서버 연결 도중 에러가 났습니다. 다시 시도해 주십시오.');
                    showToast('')
                    return false;
                    // }
                })
        }
    } else {
        $('.toast .toast-body').html('Please Buy Ticket First');
        showToast('')
    }
}

//mymcard post to community list 
export function createListToMymCard() {
    if (userId != "" && token != "" && userId != null && token != null) {

        let hashTagVal = document.getElementById("hashTag").value;
        let hashArr = hashTagVal.split("#");
        let hashTag = "";
        let count = 0;

        for (var i = 0; i < hashArr.length; i++) {
            if (hashArr[i] != "" && count < 5) {
                if (i != 0 && hashTag != "") {
                    hashTag += ","
                }
                hashTag += hashArr[i];
                count++;
            }
        }

        var data = {
            "title": document.getElementById("title").value,
            "summary": document.getElementById("summary").value,
            "author": userId,
            "hashtag": hashTag,
            "cardCategory": document.getElementById("cardCategory").value,
            "cardId": document.getElementById("cardId").value,
        };

        var jsonData = JSON.stringify(data);


        var auth = "Bearer " + token;

        const option = {
            url: '/community/free/api/postFromCard',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                "Authorization": auth
            },
            data: jsonData
        }

        axios(option)
            .then(response => {
                // $('.toast .toast-body').html('Posted successfully');
                // showToast('/freeCommunity')

                alert('Posted successfully');
                location.href = '/freeCommunity'
            })

        alert('Posted successfully');
        location.href = '/freeCommunity'

    } else {
        $('.toast .toast-body').html('Please Login first');
        showToast('/login')
    }
}

// set card to main card
export function setMainCard(id) {
    if (userId != "" && token != "" && userId != null && token != null) {

        var auth = "Bearer " + token;

        const option = {
            url: "/users/setMainCard",
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                "Authorization": auth
            },
            data: {
                "mainCardId": id
            }
        }

        axios(option)
            .then(response => {
                if (response.status == 200) {
                    $('.toast .toast-body').html('Success');
                    showToast('reload')
                }
            })
    } else {
        $('.toast .toast-body').html('Please Login first');
        showToast('/login')
    }
}

// share twitter
export function shareTwitter(url, title) {
    var sendText = title
    var sendUrl = url
    window.open("https://twitter.com/intent/tweet?text=" + sendText + "&url=" + sendUrl);
}

// share facebook
export function shareFacebook(url) {
    window.open("https://www.facebook.com/sharer/sharer.php?u=" + url);
}

export function showToast(url) {
    $('.toast').show()
    $('.toast').css('opacity', 1)

    setTimeout(() => {
        $('.toast').hide()

        if (url != '') {
            if (url == 'reload') {
                location.reload()
            } else {
                location.replace(url);
            }
        }

    }, 1000);
}

export function focusOutMenu() {
    $('.container-fluid input[type="checkbox"]').prop('checked', false)
}


// copy link
export function refcodeCopy(id) {
    var tempElem = document.createElement('textarea');
    tempElem.value = $('#' + id).val();

    console.log(tempElem.value);
    console.log(id);

    document.body.appendChild(tempElem);

    tempElem.select();
    document.execCommand("copy");
    document.body.removeChild(tempElem);

    $('.toast .toast-body').html('Copied')
    showToast('')
}
