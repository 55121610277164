import styled from "styled-components"
import * as common from "../common/commonFunction"
import commonSession from '../common/commonSession';
import { useState, useEffect } from 'react';
import $ from 'jquery'
import { useRouter } from 'next/router'


const Header = () => {


    var sessionInfo = commonSession.getSession();
    var userId = sessionInfo.userId;
    var token = sessionInfo.token;

    function moveToLink(link) {
        window.location.href = link;
    }


    const [btnText, setBtnText] = useState('');
    const [btnUrl, setBtnUrl] = useState('/login');
    const [myProject, setMyProject] = useState('');
    const [DAO, setDAO] = useState('');
    const router = useRouter();

    useEffect(() => {


        if ((userId != '' && userId != null) && (token != '' && token != null)) {
            setBtnText('로그아웃')
            setBtnUrl('#')
            // setMyProject(<li className="nav-item"><a className="nav-link active" aria-current="page" href="/mypage/myProject">내 프로젝트</a></li>);
        } else {
            setBtnText('로그인')
            setBtnUrl('/login')
            setMyProject('');
        }
    }, '');

    useEffect(() => {
        const currentUrl = router.asPath;
        if (currentUrl.includes('dao')) {
            setDAO('');
        } else {
            setDAO(<a className="nav-link active" aria-current="page" href="/dao/Main">DAO</a>);
        }
      }, [router.asPath]);

    const onClickHandler = (e) => {

        // preventDefault()

        if ((userId != '' && userId != null) && (token != '' && token != null)) {
            common.logout();
            setBtnText('Login');
            setBtnUrl('/login');
            window.location.href = '/login';
        } else {
            moveToLink('/login');
        }

    };

    const searchFunding = () => {
        alert('검색기능은 준비중입니다. 잠시만 기다려주세요! 😀');
    }

    const MainFrame = styled.div`
    width:100%;
`

    return (
        <MainFrame>
            <header>
                <div className="upper-nav-bar">
                    <nav className="navbar navbar-dark bg-dark-black navbar fixed-top"
                        aria-label="Dark offcanvas navbar">
                        <div className="container-fluid">

                            <span></span>

                            <button
                                className="navbar-toggler"
                                type="button"
                                style={{ border: "2px solid #2C3E50 !important" }}
                                onClick={() => window.location.href = '/'}
                            >
                                <div className="logo-box">
                                    <img className="logo" src="/img/renew-img/logo.svg" />
                                </div>
                                <span></span>
                            </button>

                            <div className="searchBox">
                                <input type="text" name="search_keyword" id="search-funding" value="" placeholder="작품명을 검색해보세요."></input>
                                <i className="hd-search-icon" onClick={searchFunding}></i>
                            </div>

                            {/* 웹용 메뉴 */}
                            <div className="p-version header-menu-list">
                                <div className="d-flex align-items-center justify-content-between">
                                    {DAO}
                                    <a href="/announcement">
                                        <p className="header-menu-item">공지</p>
                                    </a>
                                    <a href="/mypage/NFT">
                                        <p className="header-menu-item">NFT</p>
                                    </a>
                                    <a href="/mypage">
                                        <p className="header-menu-item">마이페이지</p>
                                    </a>
                                    <a href={btnUrl} onClick={() => onClickHandler()}>
                                        <p className="header-menu-item">{btnText}</p>
                                    </a>
                                </div>
                            </div>

                            <button
                                className="navbar-toggler hamMenu m-version"
                                type="button"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasNavbarDark"
                                aria-controls="offcanvasNavbarDark"
                                style={{ border: "none", color: "#000" }}
                                onClick={() => { }}>
                                <i className="fa-solid fa-bars fa-2x" style={{ color: "#A0FF0B" }} ></i>
                            </button>
                            <div
                                className="offcanvas offcanvas-end text-white bg-dark"
                                tabIndex="-1"
                                id="offcanvasNavbarDark"
                                aria-labelledby="offcanvasNavbarDarkLabel"
                                style={{ width: "20rem" }}>
                                <div className="offcanvas-header">
                                    <h5 className="offcanvas-title" id="offcanvasNavbarDarkLabel"></h5>
                                    <button
                                        type="button"
                                        className="btn-close btn-close-white"
                                        data-bs-dismiss="offcanvas"
                                        aria-label="Close">
                                    </button>
                                </div>
                                <div className="offcanvas-body">
                                    <ul className="navbar-nav justify-content-start">
                                        {DAO}
                                        <li className="nav-item"><a className="nav-link active"
                                            aria-current="page" href="/announcement">공지</a></li>
                                        <hr></hr>
                                        {myProject}
                                        {/* <hr></hr> */}
                                        <li className="nav-item"><a className="nav-link active"
                                            aria-current="page" href="/mypage/NFT">NFT</a></li>
                                        <li className="nav-item"><a className="nav-link active"
                                            aria-current="page" href="/mypage">마이페이지</a></li>
                                        <hr></hr>
                                        <li className="nav-item"><a className="nav-link active"
                                            aria-current="page" href={btnUrl} onClick={() => onClickHandler()}>{btnText}</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
                <script defer
                    src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"
                    integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM"
                    crossOrigin="anonymous"></script>
                <div className="toast align-items-center text-white " role="alert" aria-live="assertive" aria-atomic="true">
                    <div className="d-flex">
                        <div className="toast-body">
                        </div>
                        <button type="button" className="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close" onClick={() => $('.toast').hide()}></button>
                    </div>
                </div>
            </header>
        </MainFrame>
    );
}


export default Header